import defaultDeveloperNoInformation from './defaultDeveloperNoInformation.json';
export var defaultNewComplexData = {
    id: undefined,
    name: null,
    slug: null,
    complexStatusId: null,
    isComplex: true,
    catalogDeveloperId: null,
    // Not null
    catalogHousingClassId: null,
    // Not null
    massMarket: false,
    isActive: false,
    isInactive: false,
    houseReady: null,
    isLowRise: null,
    isProblem: false,
    nmarketAlias: null,
    nmarketId: null,
    cianId: null,
    cianAlias: null,
    avitoId: null,
    avitoAlias: null,
    avitoDescription: null,
    isIncludeFeedAvitoNoActive: false,
    deleted: false,
    cityId: null,
    addressText: null,
    areaId: null,
    municipalDistrictId: null,
    latitude: null,
    longitude: null,
    parkDistance: null,
    parkDistanceMsk: null,
    panorama: null,
    mainPhoto: null,
    commerceSquare: null,
    isCycleParking: null,
    groundsForWalkingDogs: null,
    isClosedYardCars: null,
    catalogProtectionId: null,
    videoSurveillanceSystem: null,
    isTerritoryEnclosure: null,
    specialImprovementObjects: null,
    placesForAdults: null,
    autoAvrTimeToDistrict: null,
    otAvrTimeToDistrict: null,
    metaTitle: null,
    metaKeywords: null,
    metaDescription: null,
    userId: 1,
    developerNotInfo: JSON.stringify(defaultDeveloperNoInformation),
    aliases: [],
    parkingAccessIds: [],
    parkingTypesIds: [],
    greenWoodIds: [],
    problemIds: [],
    metro: [],
    photos: [],
    playgroundsIds: [],
    playgroundSafetyIds: [],
    infrastructure: []
};
export var COMPLEX_LOADING_COMPLEXES = 'COMPLEX_LOADING_COMPLEXES';
export var COMPLEX_GET_NEW_COMPLEX_DATA = 'COMPLEX_GET_NEW_COMPLEX_DATA';
export var COMPLEX_GET_COMPLEX = 'COMPLEX_GET_COMPLEX';
export var COMPLEX_UPDATE_COMPLEX = 'COMPLEX_UPDATE_COMPLEX';
export var COMPLEX_UPDATE_COMPLEX_DATA = 'COMPLEX_UPDATE_COMPLEX_DATA';
export var COMPLEX_UPDATE_PROCESS = 'COMPLEX_UPDATE_PROCESS';
export var COMPLEX_INSERT_COMPLEX = 'COMPLEX_INSERT_COMPLEX';
export var COMPLEX_DELETE_COMPLEX = 'COMPLEX_DELETE_COMPLEX';
export var COMPLEX_SET_FIELD_VALUE = 'COMPLEX_SET_FIELD_VALUE';
export var PARKING_ACCESS_ID = 5;
export var GREENWOOD_ID = 3;
