function _typeof(o) {
    "@babel/helpers - typeof";
    return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o);
}
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t)
    return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i))
        return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
} return ("string" === r ? String : Number)(t); }
import { isEqual as _isEqual } from 'lodash';
import { COMPLEX_GET_COMPLEX, COMPLEX_SET_FIELD_VALUE, FAIL, START, STOP, SUCCESS, COMPLEX_GET_NEW_COMPLEX_DATA, COMPLEX_UPDATE_COMPLEX_DATA, defaultNewComplexData, COMPLEX_UPDATE_PROCESS } from '../../constants';
var initialState = {
    data: null,
    changedData: {},
    complexPhotosActors: {
        added: [],
        deleted: []
    },
    savingProgress: false,
    saving: false,
    saved: false,
    savingError: false,
    loading: false,
    loaded: false
};
var defaultSuccessData = {
    complexPhotosActors: _objectSpread({}, initialState.complexPhotosActors),
    changedData: {},
    saving: false,
    saved: false,
    savingError: false
};
var complex = function complex() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
        case COMPLEX_GET_COMPLEX + START:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: true,
                loaded: false
            });
        case COMPLEX_GET_COMPLEX + SUCCESS:
            return _objectSpread(_objectSpread(_objectSpread({}, state), {}, {
                data: action.payload
            }, defaultSuccessData), {}, {
                loaded: true
            });
        case COMPLEX_GET_NEW_COMPLEX_DATA:
            return _objectSpread(_objectSpread(_objectSpread({}, state), {}, {
                data: _objectSpread({}, defaultNewComplexData)
            }, defaultSuccessData), {}, {
                loaded: true
            });
        case COMPLEX_GET_COMPLEX + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                loaded: false,
                data: null
            });
        case COMPLEX_GET_COMPLEX + STOP:
            return _objectSpread(_objectSpread({}, state), {}, {
                loading: false
            });
        case COMPLEX_UPDATE_COMPLEX_DATA + START:
            return _objectSpread(_objectSpread({}, state), {}, {
                saving: true,
                saved: false,
                savingError: false
            });
        case COMPLEX_UPDATE_COMPLEX_DATA + SUCCESS:
            return _objectSpread(_objectSpread({}, state), {}, {
                saving: false,
                saved: true,
                savingError: false
            });
        case COMPLEX_UPDATE_COMPLEX_DATA + FAIL:
            return _objectSpread(_objectSpread({}, state), {}, {
                saving: false,
                saved: false,
                savingError: true
            });
        case COMPLEX_UPDATE_PROCESS + STOP:
            return _objectSpread(_objectSpread({}, state), {}, {
                savingProgress: false,
                saved: false,
                savingError: false
            });
        case COMPLEX_SET_FIELD_VALUE:
            {
                var payload = action.payload;
                var changedValues = _objectSpread(_objectSpread({}, state.changedData), payload);
                var newValues = Object.keys(changedValues).reduce(function (acc, key) {
                    return _isEqual(changedValues[key], state.data[key]) ? acc : _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, key, changedValues[key]));
                }, {});
                return _objectSpread(_objectSpread({}, state), {}, {
                    changedData: _objectSpread({}, newValues)
                });
            }
        default:
            return state;
    }
};
export default complex;
