export var makeDataSource = function makeDataSource(data, selected, keyField) {
    var idField = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'id';
    var nameField = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'name';
    return data.map(function (item) {
        return {
            id: item[idField],
            name: item[nameField],
            added: selected.findIndex(function (el) {
                return el[keyField] === item[idField];
            }) === -1
        };
    });
};
